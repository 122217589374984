import { Component, Input, OnInit } from "@angular/core";
import { Subscription } from "rxjs";

import { TestService } from "src/app/services/test.service";
import { TEST_NAVBAR_UI } from "../../constants/ui-strings";

@Component({
  selector: "app-test-navbar",
  templateUrl: "./test-navbar.component.html",
  styleUrl: "./test-navbar.component.scss",
})
export class TestNavbarComponent {
  @Input() showCompleteNavbar: boolean;

  submitQuizPopup: boolean = false;
  totalQuestions: number = 0;
  attempted: number = 0;
  skipped: number = 0;
  testNavbarUIStrings = TEST_NAVBAR_UI;

  showCompleteNavbarSubscription$: Subscription;

  showFinalQuizPopup(): void {
    this.submitQuizPopup = true;
  }
}
