<div class="popup-content">
  <h2>{{ confirmSubmitUIStrings.quiz_statistics }}</h2>
  <p>
    {{ confirmSubmitUIStrings.total_questions }}:
    <span>{{ totalQuestions }}</span>
  </p>
  <p>
    {{ confirmSubmitUIStrings.attempted }}: <span>{{ attemptedQuestions }}</span>
  </p>
  <p>
    {{ confirmSubmitUIStrings.unanswered }}: <span>{{ unansweredQuestions }}</span>
  </p>
  <button
    class="p-button-success"
    pButton
    pRipple
    [label]="confirmSubmitUIStrings.finish_test"
    (click)="finishTest()"
  ></button>
</div>
