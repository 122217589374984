import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";

import { TestService } from "src/app/services/test.service";
import { QUESTION_PAGE_UI } from "../../constants/ui-strings";

@Component({
  selector: "app-question-page",
  templateUrl: "./question-page.component.html",
  styleUrl: "./question-page.component.scss",
})
export class QuestionPageComponent implements OnInit, OnDestroy {
  questionData: any = {}; // actual format may vary as per api data
  currentQuestionNumber: number = 1;
  selectedOption: string = "";
  questionPageUIStrings = QUESTION_PAGE_UI;

  changeQuestionSubscription$: Subscription;

  constructor(private testService: TestService) {}

  ngOnInit(): void {
    this.questionData =
      this.testService.questionsList[this.currentQuestionNumber - 1];
    this.changeQuestionSubscription$ =
      this.testService.questionNumber$.subscribe({
        next: (newQuestionNumber) => {
          this.currentQuestionNumber = newQuestionNumber;
          this.questionData =
            this.testService.questionsList[this.currentQuestionNumber - 1];
        },
        error: (error) => {
          console.log(error);
        },
      });
  }

  next(): void {
    this.testService.changeQuestion(this.currentQuestionNumber + 1);
  }

  clear(): void {
    this.selectedOption = "";
  }

  saveResponse(option: string): void {
    this.selectedOption = option;
  }

  ngOnDestroy(): void {
    this.changeQuestionSubscription$.unsubscribe();
  }
}
