<p-menubar class="menu-bar">
  <ng-template pTemplate="start">
    <div class="logo-container">
      <img
        src="assets/WatchGuard_Logo.svg"
        alt="logo"
        height="40"
        class="logo"
      />
    </div>
  </ng-template>

  <ng-template pTemplate="end" *ngIf="showCompleteNavbar">
    <div class="inline-container">
      <div class="inline-container">
        <i class="pi pi-clock"></i>
        <p>00:00:00</p>
      </div>
      <p>{{ testNavbarUIStrings.tab_count }}: 3</p>
      <p>
        <button
          class="p-button-success"
          pButton
          pRipple
          [label]="testNavbarUIStrings.submit"
          (click)="showFinalQuizPopup()"
        ></button>
      </p>
    </div>
  </ng-template>
</p-menubar>
<p-dialog [(visible)]="submitQuizPopup">
  <app-confirm-submit
    [totalQuestions]="totalQuestions"
    [attempted]="attempted"
    [skipped]="skipped"
  ></app-confirm-submit>
</p-dialog>
