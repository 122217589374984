<div class="summary-container">
  <h2>Test Submission Summary</h2>
  
  <p class="status-text"><strong>Test Completed</strong></p>

  <div class="summary-details">
    <p><strong>Total Questions:</strong> {{ totalQuestions }}</p>
    <p><strong>Attempted Questions:</strong> {{ attemptedQuestions }}</p>
    <p><strong>Unanswered Questions:</strong> {{ unansweredQuestions }}</p>
  </div>
  
  <div class="completion-message">
    <p><strong>Thank you for completing the test!</strong></p>
  </div>
</div>
  