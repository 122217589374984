import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { WebcamModule } from "ngx-webcam";
import { MenubarModule } from "primeng/menubar";
import { ButtonModule } from "primeng/button";
import { RippleModule } from "primeng/ripple";
import { DialogModule } from "primeng/dialog";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { CameraComponent } from "./components/camera/camera.component";
import { CandidateFormComponent } from "./components/candidate-form/candidate-form.component";
import { ConfirmSubmitComponent } from "./components/confirm-submit/confirm-submit.component";
import { TestNavbarComponent } from "./components/test-navbar/test-navbar.component";
import { AssessmentPageComponent } from "./components/assessment-page/assessment-page.component";
import { QuestionNumberComponent } from "./components/question-number/question-number.component";
import { QuestionPageComponent } from "./components/question-page/question-page.component";

@NgModule({
  declarations: [
    AppComponent,
    CameraComponent,
    CandidateFormComponent,
    ConfirmSubmitComponent,
    TestNavbarComponent,
    AssessmentPageComponent,
    QuestionNumberComponent,
    QuestionPageComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    WebcamModule,
    MenubarModule,
    ButtonModule,
    RippleModule,
    DialogModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
