import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { API_ROUTES } from "./constants/constants";
import { AssessmentPageComponent } from "./components/assessment-page/assessment-page.component";

const routes: Routes = [
  { path: API_ROUTES.candidate_form_url, component: AssessmentPageComponent },
  { path: API_ROUTES.assessment_page_url, component: AssessmentPageComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
