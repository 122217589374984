import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CONFIRM_SUBMIT_UI } from '../../constants/ui-strings'
import { QuestionsService } from 'src/app/services/questions.service';

@Component({
  selector: 'app-confirm-submit',
  templateUrl: './confirm-submit.component.html',
  styleUrl: './confirm-submit.component.scss'
})
export class ConfirmSubmitComponent {
  attemptedQuestions: number = 0;
  totalQuestions: number = 0;
  unansweredQuestions: number = 0;

  confirmSubmitUIStrings = CONFIRM_SUBMIT_UI;

  constructor(private router: Router, private questionsService: QuestionsService) {}

  ngOnInit(): void {
    this.questionsService.attemptedQuestions$.subscribe((count) => {
      this.attemptedQuestions = count;
    });

    this.totalQuestions = this.questionsService.getTotalQuestions();

    this.questionsService.unansweredQuestions$.subscribe((count) => {
      this.unansweredQuestions = count;
    });
  }

  finishTest(): void{
    this.router.navigate(['/submit-test']);
  }
  
}
