export const CANDIDATE_FORM_UI = {
    organization_title: 'Watchguard Technologies',
    organization_description: 'WatchGuard\'s Unified Security Platform is built from the ground up to enable efficient, powerful security services with increased scale and velocity while gaining operational efficiencies.WatchGuard\'s unified approach makes it easy to deploy and manage the breadth of products and services you need to modernize, mature, and scale your security in the face of today’s increasingly complex threat landscape.',
    test_title: 'Test Title',
    test_duration: 'Total Duration',
    start_date_time: 'Start Date & Time',
    end_date_time: 'End Date & Time',
    start_test: 'Start Test',
    clear_test: 'Clear'
}

export const CONFIRM_SUBMIT_UI = {
    total_questions: 'Total Questions',
    attempted: 'Attempted',
    skipped: 'Skipped',
    quiz_statistics: 'Quiz Statistics',
    finish_test: 'Finish Test'
}

export const QUESTION_PAGE_UI = {
    next: 'Next',
    clear: 'Clear'
}

export const TEST_NAVBAR_UI = {
    tab_count: 'Tab Count',
    submit: 'Submit'
}
