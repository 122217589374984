<div class="home-container">
  <div class="quiz-form-section-container">
    <div class="logo-container">
      <img
        src="assets/WatchGuard_Logo.svg"
        alt="logo"
        height="80" 
        width="auto" 
        class="logo"
      />
    </div>
    
    <h1 class="red">{{ candidateFormUIString.organization_title }}</h1>
    <p>{{ candidateFormUIString.organization_description }}</p>
    <h3>{{ candidateFormUIString.test_title }}</h3>
    <div class="quiz-metadata-container">
      <p>{{ candidateFormUIString.test_duration }}: 60 mins</p>
      <p>{{ candidateFormUIString.start_date_time }}: 25 December 2024 10:00 AM</p>
      <p>{{ candidateFormUIString.end_date_time }}: 25 December 2024 12:00 PM</p>
    </div>
  </div>
  <div class="quiz-form-section-container">
    <form
      class="candidate-form"
      [formGroup]="candidateDetailsForm"
    >
      <div class="candidate-form-input">
        <input
          type="text"
          placeholder="Enter your Name"
          formControlName="name"
          pInputText
        />
      </div>
      <div class="inline-container">
        <div class="candidate-form-input">
          <input
            type="text"
            formControlName="college"
            placeholder="Enter your College Name"
            pInputText
          />
        </div>
      </div>
      <div class="candidate-form-input">
        <input
          type="email"
          formControlName="email"
          placeholder="Enter your Email"
          pInputText
        />
      </div>
      <div class="candidate-form-input">
        <input
          type="Password"
          formControlName="password"
          placeholder="Enter password"
          pInputText
        />
      </div>
      <div class="bttn-center">
        <p-button
          type="submit"
          class="btn-design"
          severity="success"
          [label]="candidateFormUIString.start_test"
          [outlined]="true"
          (click)="startQuiz()"
        ></p-button>
        <p-button
          type="reset"
          class="btn-design"
          severity="danger"
          [label]="candidateFormUIString.clear_test"
          [outlined]="true"
          (click)="clearInputs()"
        ></p-button>
      </div>
    </form>
  </div>
</div>
