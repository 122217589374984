import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AssessmentPageComponent } from "./components/assessment-page/assessment-page.component";
import { CandidateFormComponent } from "./components/candidate-form/candidate-form.component";
import { SubmitTestComponent } from "./components/submit-test/submit-test.component";

const routes: Routes = [
  { path: '', component: CandidateFormComponent },
  { path: 'test', component: AssessmentPageComponent},
  { path: 'submit-test', component: SubmitTestComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
