import { Component } from '@angular/core';
import { QuestionsService } from 'src/app/services/questions.service';

@Component({
  selector: 'app-submit-test',
  standalone: true,
  imports: [],
  templateUrl: './submit-test.component.html',
  styleUrl: './submit-test.component.scss'
})
export class SubmitTestComponent {
  attemptedQuestions: number = 0;
  totalQuestions: number = 0;
  unansweredQuestions: number = 0;


  constructor(private questionsService: QuestionsService){}

  ngOnInit(): void {
    this.totalQuestions = this.questionsService.getTotalQuestions();

    this.questionsService.attemptedQuestions$.subscribe((count) => {
      this.attemptedQuestions = count;
    });

    this.questionsService.unansweredQuestions$.subscribe((count) => {
      this.unansweredQuestions = count;
    });
  }
}
