import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormControl, FormGroup, Validators } from "@angular/forms";

import { Subscription } from "rxjs";

import { CANDIDATE_FORM_UI } from "../../constants/ui-strings";

@Component({
  selector: "app-candidate-form",
  templateUrl: "./candidate-form.component.html",
  styleUrl: "./candidate-form.component.scss",
})
export class CandidateFormComponent {
  @Input() disableButton: boolean;

  test_id: string;
  candidateFormUIString = CANDIDATE_FORM_UI;
  candidateDetailsForm!: FormGroup;

  getQuizQuestionSubscription$: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.test_id = this.route.snapshot.params["test_id"];
    this.candidateDetailsForm = new FormGroup({
      name: new FormControl("", [Validators.required]),
      email: new FormControl("", [Validators.required, Validators.email]),
      college: new FormControl(""),
    });
  }

  startQuiz(): void {
    this.router.navigate(['test', 1234, 'start']);
  }

  clearInputs(): void {
    this.candidateDetailsForm.reset();
  }

  checkBrowserPermission(): Promise<MediaStream> {
    return navigator.mediaDevices.getUserMedia({ video: true });
  }
}
