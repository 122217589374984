import { Component, Input } from "@angular/core";
import { Subscription } from "rxjs";

import { TimerService } from "src/app/services/timer.service";
import { TEST_NAVBAR_UI } from "../../constants/ui-strings";

@Component({
  selector: "app-test-navbar",
  templateUrl: "./test-navbar.component.html",
  styleUrl: "./test-navbar.component.scss",
})
export class TestNavbarComponent {
  @Input() showCompleteNavbar: boolean;

  submitQuizPopup: boolean = false;
  totalQuestions: number = 0;
  attempted: number = 0;
  skipped: number = 0;
  testNavbarUIStrings = TEST_NAVBAR_UI;
  timeRemaining: number = 0;
  timerSubscription: Subscription | undefined;

  constructor(private timerService: TimerService) { }

  ngOnInit() {
    this.timerSubscription = this.timerService.timeRemaining$.subscribe(time => {
      this.timeRemaining = time;
    });
  }

  formatTime(time: number): string {
    return this.timerService.formatTime(time);
  }

  ngOnDestroy() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }

  showCompleteNavbarSubscription$: Subscription;

  showFinalQuizPopup(): void {
    this.submitQuizPopup = true;
  }
}
