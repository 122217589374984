import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { TestService } from "src/app/services/test.service";

@Component({
  selector: "app-question-number",
  templateUrl: "./question-number.component.html",
  styleUrl: "./question-number.component.scss",
})
export class QuestionNumberComponent implements OnInit, OnDestroy {
  questionsList: any[] = []; // actual format may vary as per api data
  questionNumberSelected!: number;

  changeQuestionNumber$: Subscription;

  constructor(private testService: TestService) {}

  ngOnInit(): void {
    this.questionsList = this.testService.questionsList;
    this.changeQuestionNumber$ = this.testService.questionNumber$.subscribe({
      next: (newQuestionNumber) => {
        this.questionNumberSelected = newQuestionNumber - 1;
      },
    });
  }

  displayQuestion(num: number): void {
    this.questionNumberSelected = num;
    this.testService.changeQuestion(this.questionNumberSelected + 1);
  }

  ngOnDestroy(): void {
    this.changeQuestionNumber$.unsubscribe();
  }
}
