import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { WebcamImage, WebcamInitError, WebcamUtil } from "ngx-webcam";
import { Observable, Subject } from "rxjs";

@Component({
  selector: "app-camera",
  templateUrl: "./camera.component.html",
  styleUrl: "./camera.component.scss",
})
export class CameraComponent implements OnInit {
  @Output() pictureTaken = new EventEmitter<WebcamImage>();
  @Output() webCamError = new EventEmitter();

  multipleWebcamsAvailable: boolean = false;
  showWebcam: boolean = true;
  allowCameraSwitch: boolean = true;
  deviceId: string;
  webCamPermissionDenied: boolean;
  errors: WebcamInitError[] = [];
  snapshot: ReturnType<typeof setInterval>;

  trigger: Subject<void> = new Subject<void>();

  @ViewChild("snapshot", { static: false }) takeSnapshotButton: ElementRef;

  ngOnInit(): void {
    WebcamUtil.getAvailableVideoInputs().then(
      (mediaDevices: MediaDeviceInfo[]) => {
        this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
      }
    );
  }

  handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
    this.webCamPermissionDenied = true;
    this.webCamError.emit();
  }

  handleImage(webcamImage: WebcamImage): void {
    this.pictureTaken.emit(webcamImage);
  }

  cameraWasSwitched(deviceId: string): void {
    this.deviceId = deviceId;
  }

  takeSnapshot(): void {
    this.trigger.next();
  }

  get invokeObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

}
