<div class="quiz-question-div">
  <div class="question-div">
    <p class="question-title">{{ questionData.question_text }}</p>
    <div class="marking-scheme-div"><i>Correct +1 Wrong -0.25</i></div>
    <span class="option-container">
      <input
        type="radio"
        id="option1"
        name="options"
        [checked]="selectedOption === questionData.options.option1"
        (click)="saveResponse(questionData.options.option1)"
      />
      <label for="option1">{{ questionData.options.option1 }}</label>
    </span>
    <span>
      <input
        type="radio"
        id="option2"
        name="options"
        [checked]="selectedOption === questionData.options.option2"
        (click)="saveResponse(questionData.options.option2)"
      />
      <label for="option2">{{ questionData.options.option2 }}</label>
    </span>
    <span>
      <input
        type="radio"
        id="option3"
        name="options"
        [checked]="selectedOption === questionData.options.option3"
        (click)="saveResponse(questionData.options.option3)"
      />
      <label for="option3">{{ questionData.options.option3 }}</label>
    </span>
    <span>
      <input
        type="radio"
        id="option4"
        name="options"
        [checked]="selectedOption === questionData.options.option4"
        (click)="saveResponse(questionData.options.option4)"
      />
      <label for="option4">{{ questionData.options.option4 }}</label>
    </span>
  </div>
  <div class="action-buttons">
    <p-button
      severity="success"
      [label]="questionPageUIStrings.next"
      [outlined]="true"
      (click)="next()"
    ></p-button>
    <p-button
      severity="danger"
      [label]="questionPageUIStrings.clear"
      [outlined]="true"
      (click)="clear()"
    ></p-button>
  </div>
</div>
