import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { APP_CONST } from "../../constants/constants";

@Component({
  selector: "app-assessment-page",
  templateUrl: "./assessment-page.component.html",
  styleUrl: "./assessment-page.component.scss",
})
export class AssessmentPageComponent implements OnInit {
  fullNavbar: boolean = false;
  showCandidateForm: boolean = false;
  showTestQuestions: boolean = false;

  constructor(private router: Router) {}

  ngOnInit(): void {
    const currentUrl: string = this.router.url;

    if (currentUrl.endsWith('home')) {
      this.showCandidateForm = true;
    } else if (currentUrl.endsWith(APP_CONST.test)) {
      this.showTestQuestions = true;
      this.fullNavbar = true;
    }
  }
}
