import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class QuestionsService {

  private attemptedQuestionsSubject = new BehaviorSubject<number>(0);
  private unansweredQuestionsSubject = new BehaviorSubject<number>(30);
  private totalQuestions = 30

  // Observable streams to be used by other components
  attemptedQuestions$ = this.attemptedQuestionsSubject.asObservable();
  unansweredQuestions$ = this.unansweredQuestionsSubject.asObservable();

  constructor() {}

  // Methods to update question status
  incrementAttempted() {
    this.attemptedQuestionsSubject.next(this.attemptedQuestionsSubject.value + 1);
  }

  getTotalQuestions():number {
    return this.totalQuestions
  }

  decrementUnanswered() {
    this.unansweredQuestionsSubject.next(this.unansweredQuestionsSubject.value - 1);
  }
}
