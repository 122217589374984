export const questionsArray: any = [
  {
    question_text: 'Which is an optional constraint in REST API 1?',
    options: {
      option1: 'Client-Server Architecture',
      option2: 'Statelessness',
      option3: 'Code on Demand',
      option4: 'Uniform Interface',
    }
  },
  {
    question_text: 'Which is the primary protocol used in REST?',
    options: {
      option1: 'HTTP',
      option2: 'FTP',
      option3: 'SMTP',
      option4: 'TCP',
    }
  },
  {
    question_text: 'What does REST stand for?',
    options: {
      option1: 'Representational State Transfer',
      option2: 'Remote Execution State Transfer',
      option3: 'Representational Syntax Transformation',
      option4: 'Rapid Execution Service Transfer',
    }
  },
  {
    question_text: 'Which HTTP method is idempotent?',
    options: {
      option1: 'POST',
      option2: 'PUT',
      option3: 'PATCH',
      option4: 'DELETE',
    }
  },
  {
    question_text: 'Which constraint ensures a single communication between client and server?',
    options: {
      option1: 'Layered System',
      option2: 'Statelessness',
      option3: 'Cacheable',
      option4: 'Uniform Interface',
    }
  },
  {
    question_text: 'Which status code indicates a successful request?',
    options: {
      option1: '200',
      option2: '404',
      option3: '500',
      option4: '403',
    }
  },
  {
    question_text: 'Which method is used to update a resource in REST?',
    options: {
      option1: 'GET',
      option2: 'POST',
      option3: 'PUT',
      option4: 'DELETE',
    }
  },
  {
    question_text: 'Which method retrieves information from the server?',
    options: {
      option1: 'POST',
      option2: 'PUT',
      option3: 'GET',
      option4: 'DELETE',
    }
  },
  {
    question_text: 'Which method creates a new resource in REST?',
    options: {
      option1: 'GET',
      option2: 'POST',
      option3: 'PUT',
      option4: 'DELETE',
    }
  },
  {
    question_text: 'What does idempotent mean in the context of REST?',
    options: {
      option1: 'Can be called multiple times without different outcomes',
      option2: 'Can only be called once',
      option3: 'Changes the server state',
      option4: 'Is a read-only operation',
    }
  },
  {
    question_text: 'Which of the following is not a REST architectural constraint?',
    options: {
      option1: 'Client-Server',
      option2: 'Cacheability',
      option3: 'Statelessness',
      option4: 'Session Persistence',
    }
  },
  {
    question_text: 'Which HTTP status code indicates that a resource has been created?',
    options: {
      option1: '200',
      option2: '201',
      option3: '404',
      option4: '500',
    }
  },
  {
    question_text: 'Which of the following is a benefit of REST?',
    options: {
      option1: 'High Coupling',
      option2: 'Scalability',
      option3: 'Stateful Communication',
      option4: 'Complex Implementation',
    }
  },
  {
    question_text: 'Which of the following HTTP methods is used to delete a resource?',
    options: {
      option1: 'GET',
      option2: 'POST',
      option3: 'PUT',
      option4: 'DELETE',
    }
  },
  {
    question_text: 'What does the uniform interface constraint imply?',
    options: {
      option1: 'Different interfaces for different clients',
      option2: 'A single, consistent interface',
      option3: 'Interfaces change frequently',
      option4: 'No interface at all',
    }
  },
  {
    question_text: 'Which of the following is a cacheable method?',
    options: {
      option1: 'GET',
      option2: 'POST',
      option3: 'PUT',
      option4: 'DELETE',
    }
  },
  {
    question_text: 'Which status code indicates forbidden access?',
    options: {
      option1: '200',
      option2: '403',
      option3: '404',
      option4: '500',
    }
  },
  {
    question_text: 'What is the primary advantage of statelessness in REST?',
    options: {
      option1: 'Easier caching',
      option2: 'Simplified client-server interactions',
      option3: 'Enhanced security',
      option4: 'Improved server performance',
    }
  },
  {
    question_text: 'Which of the following is an essential aspect of the REST architecture?',
    options: {
      option1: 'High Latency',
      option2: 'Tightly Coupled Components',
      option3: 'Statelessness',
      option4: 'Session Persistence',
    }
  },
  {
    question_text: 'Which status code indicates that a resource was not found?',
    options: {
      option1: '200',
      option2: '404',
      option3: '500',
      option4: '403',
    }
  },
  {
    question_text: 'Which of the following is not an HTTP method used in REST?',
    options: {
      option1: 'TRACE',
      option2: 'CONNECT',
      option3: 'LINK',
      option4: 'OPTIONS',
    }
  },
  {
    question_text: 'Which of the following best describes "Client-Server Architecture"?',
    options: {
      option1: 'Clients and servers must be on the same machine',
      option2: 'Clients and servers can be independently developed',
      option3: 'Clients maintain session state',
      option4: 'Servers handle client-side logic',
    }
  },
  {
    question_text: 'What is the purpose of the "Layered System" constraint?',
    options: {
      option1: 'To improve scalability and manageability',
      option2: 'To ensure stateful communication',
      option3: 'To maintain session information',
      option4: 'To enable direct client-server interaction',
    }
  },
  {
    question_text: 'Which HTTP status code indicates an internal server error?',
    options: {
      option1: '200',
      option2: '403',
      option3: '500',
      option4: '404',
    }
  },
  {
    question_text: 'Which of the following is true about "Code on Demand" in REST?',
    options: {
      option1: 'It is a mandatory constraint',
      option2: 'It allows servers to extend client functionality by sending executable code',
      option3: 'It requires clients to execute server code',
      option4: 'It is related to caching mechanisms',
    }
  },
  {
    question_text: 'What does the term "resource" refer to in REST?',
    options: {
      option1: 'A server-side script',
      option2: 'A client application',
      option3: 'Any piece of information that can be named',
      option4: 'A database entry',
    }
  },
  {
    question_text: 'Which HTTP status code indicates a bad request?',
    options: {
      option1: '200',
      option2: '400',
      option3: '404',
      option4: '500',
    }
  },
  {
    question_text: 'Which of the following HTTP methods is considered safe?',
    options: {
      option1: 'POST',
      option2: 'DELETE',
      option3: 'GET',
      option4: 'PUT',
    }
  },
  {
    question_text: 'Which of the following is not a characteristic of RESTful services?',
    options: {
      option1: 'Stateful interactions',
      option2: 'Stateless interactions',
      option3: 'Cacheable responses',
      option4: 'Uniform interface',
    }
  },
  {
    question_text: 'Which of the following is a RESTful principle?',
    options: {
      option1: 'Server-Centric',
      option2: 'Resource-Centric',
      option3: 'Protocol-Centric',
      option4: 'Client-Centric',
    }
  }
];
