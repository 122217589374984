import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

import { questionsArray } from "./temp";

@Injectable({
  providedIn: "root",
})
export class TestService {
  // this format will change based on backend
  questionsList: any[] = questionsArray;

  questionNumber$ = new BehaviorSubject<number>(1);
  activeTest = new BehaviorSubject<boolean>(false);

  changeQuestion(questionNumber: number) {
    this.questionNumber$.next(questionNumber);
  }
}
