<div class="camera-container">
  <webcam
    *ngIf="showWebcam"
    [height]="200"
    [width]="200"
    [imageQuality]="0.3"
    [trigger]="invokeObservable"
    [allowCameraSwitch]="allowCameraSwitch"
    (imageCapture)="handleImage($event)"
    (initError)="handleInitError($event)"
    (cameraSwitched)="cameraWasSwitched($event)"
  ></webcam>
  <h3 *ngIf="webCamPermissionDenied">Please Turn On Web Cam</h3>
</div>
