import { Component, Input } from '@angular/core';

import { CONFIRM_SUBMIT_UI } from '../../constants/ui-strings'

@Component({
  selector: 'app-confirm-submit',
  templateUrl: './confirm-submit.component.html',
  styleUrl: './confirm-submit.component.scss'
})
export class ConfirmSubmitComponent {
  @Input() totalQuestions: number = 30;
  @Input() attempted: number = 0;
  @Input() skipped: number = 0

  confirmSubmitUIStrings = CONFIRM_SUBMIT_UI;

  finishTest(): void{
    // API Call to verify time
  }
  
}
