<app-test-navbar [showCompleteNavbar]="fullNavbar"></app-test-navbar>
<div>
  <div *ngIf="showCandidateForm">
    <app-candidate-form></app-candidate-form>
  </div>
  <div *ngIf="showTestQuestions" class="test-play-screen-div">
    <div class="scrollable">
      <app-question-number></app-question-number>
    </div>
    <div class="non-scrollable">
      <app-question-page></app-question-page>
    </div>
  </div>
  <div class="camera-container">
    <app-camera></app-camera>
  </div>
</div>
