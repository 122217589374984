import { Injectable } from '@angular/core';
import { BehaviorSubject  } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TimerService {
  private timeRemaining: number = 3600;
  private timer: any;
  private isRunning: boolean = false;

  private timeRemainingSubject = new BehaviorSubject<number>(this.timeRemaining);

  timeRemaining$ = this.timeRemainingSubject.asObservable();

  constructor() {}

  startTimer() {
    if (this.isRunning) return;

    this.isRunning = true;
    this.timer = setInterval(() => {
      if (this.timeRemaining > 0) {
        this.timeRemaining--;
        this.timeRemainingSubject.next(this.timeRemaining);
      } else {
        clearInterval(this.timer);
        this.isRunning = false;
        this.timeRemainingSubject.next(0);
        alert('Time is up! The test is over.');
      }
    }, 1000);
  }

  formatTime(time: number): string {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;
  
    return `${this.pad(hours)}:${this.pad(minutes)}:${this.pad(seconds)}`;
  }

  private pad(num: number): string {
    return num < 10 ? '0' + num : num.toString();
  }
}
